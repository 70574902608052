<template>
  <div class="content_box">
    <div class="content_box_title">
      <div @click="returnPage"><i class="iconfont">&#xe625;</i>绑定手机</div>
    </div>
    <div class="content_box_edit">
      <div class="content_box_edit_input">
        <span>手机号</span>
        <input
          type="text"
          v-model="list.mobile"
          maxlength="11"
          placeholder="请输入手机号码"
        />
      </div>
      <div class="content_box_edit_input">
        <span>密码</span>
        <input
          type="password"
          v-model="list.password"
          maxlength="20"
          placeholder="请输入密码6-20位字符"
        />
      </div>
      <div class="content_box_edit_input">
        <span>验证码</span>
        <input
          class="input_code"
          type="text"
          v-model="list.code"
          maxlength="4"
          placeholder="请输入验证码"
        />
        <button @click="codeBut" v-if="codeLabel">{{ codeName }}</button>
        <div v-else>{{ codeName }}</div>
      </div>
    </div>
    <button class="content_box_but" @click="determineBut">确定</button>
  </div>
</template>

<script>
import { senCode, confirmBinding } from "@/api/personal";
export default {
  data() {
    return {
      list: { mobile: "", code: "", password: "" },
      codeName: "获取验证码",
      codeLabel: true,
    };
  },
  methods: {
    //返回上一页
    returnPage() {
      this.$router.push({
        path: "myStudy",
        query: { option: 5, path: "accountSettings" },
      });
    },
    //验证码
    codeBut() {
      let that = this;
      console.log();
      let regular = /^1(3|4|5|7|8)\d{9}$/;
      if (that.list.mobile == "") {
        this.$message.error("手机号不能为空");
        return false;
      } else if (!regular.test(that.list.mobile)) {
        this.$message.error("请输入正确的手机号");
        return false;
      } else {
        that.codeLabel = false;
        senCode(that.list.mobile).then((res) => {
          if (res.code == 200) {
            var count = 60;
            var timer = setInterval(function () {
              count--;
              if (count <= 0) {
                clearInterval(timer);
                that.codeName = "重新发送";
                that.codeLabel = true;
              } else {
                that.codeName = count + "s";
              }
            }, 1000);
          } else {
            this.$message.error(res.msg);
          }
        });
      }
    },
    //确认
    determineBut() {
      // console.log(this.list);
      let that = this;
      let regular = /^1(3|4|5|7|8)\d{9}$/;
      if (that.list.mobile == "") {
        this.$message.error("手机号不能为空");
        return false;
      } else if (!regular.test(that.list.mobile)) {
        this.$message.error("请输入正确的手机号");
        return false;
      } else if (that.list.password == "") {
        this.$message.error("密码不能为空");
        return false;
      } else if (that.list.code.length != 4) {
        this.$message.error("请输4位正确的验证码");
        return false;
      } else {
        confirmBinding(that.list).then((res) => {
          if (res.code == 200) {
            this.$message({
              message: "绑定成功",
              type: "success",
            });
            setTimeout(() => {
              this.$router.push({
                path: "myStudy",
                query: { option: 5, path: "accountSettings" },
              });
            }, 1000);
          } else {
            this.$message.error(res.msg);
          }
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.content_box {
  height: 748px;
}

.content_box_title {
  padding-left: 33px;
  border-bottom: 1px solid #eeeeee;
  text-align: left;
  & > div {
    font-size: 18px;
    font-weight: 500;
    color: #333333;
    line-height: 60px;
    height: 60px;
    width: 110px;
    cursor: pointer;
    & > .iconfont {
      font-size: 18px;
      padding-right: 12px;
    }
  }
}

.content_box_edit {
  margin-top: 105px;
  & > .content_box_edit_input {
    margin-bottom: 23px;
    & > span {
      font-size: 14px;
      font-weight: 500;
      color: #333333;
      line-height: 38px;
      margin-right: 21px;
      width: 50px;
      display: inline-block;
      vertical-align: top;
    }
    & > input {
      width: 295px;
      height: 38px;
      background: #ffffff;
      border: 1px solid #e6e6e6;
      border-radius: 2px;
      font-size: 14px;
      font-weight: 500;
      color: #333333;
      line-height: 38px;
      padding-left: 10px;
      display: inline-block;
      vertical-align: top;
    }
    & > .input_code {
      width: 198px;
    }
    & > button {
      width: 96px;
      background: #fff;
      border: 1px solid #e6e6e6;
      border-left: 0px;
      font-size: 14px;
      font-weight: 500;
      color: #0d79ff;
      height: 38px;
      display: inline-block;
      vertical-align: top;
    }
    & > div {
      width: 96px;
      background: #fff;
      border: 1px solid #e6e6e6;
      border-left: 0px;
      font-size: 14px;
      font-weight: 500;
      color: #0d79ff;
      line-height: 38px;
      height: 38px;
      display: inline-block;
      vertical-align: top;
    }
  }
}

.content_box_but {
  width: 295px;
  height: 40px;
  background: #0d79ff;
  border-radius: 2px;
  font-size: 14px;
  font-weight: 500;
  color: #ffffff;
  line-height: 40px;
  margin-left: 73px;
}
</style>